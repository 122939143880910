<template>
  <section class="filterBar">
    <div class="filterBar__section">
      <div class="filterBar__sectionPart">
        <span class="filterBar__numData" v-if="hasFilters">{{ $store.state.records.documentsInFilter }}</span>
        <div class="filterBar__search">
          <iconic class="filterBar__iconSearch" name="search_new" />
          <input class="filterBar__searchInput" type="text" placeholder="Buscar" v-model="search" />
        </div>
        <v-md-date-range-picker
          class="filterBar__rangePicker"
          ref="myDatePicker"
          opens="left"
          :startDate="dateInCalendar"
          :endDate="dateTo"
          @change="handleChange"
          :key="resetCalendar"
        ></v-md-date-range-picker>
        <div class="filterBar__item" @click="$refs.myDatePicker.togglePicker()">
          <iconic name="calendar"></iconic>
          <span class="filterBar__datesInFilter">{{ datesInFilter }}</span>
        </div>
      </div>
      <div class="filterBar__sectionPart">
        <select class="filterBar__item filterBar__item--select" v-model="city">
          <option value="">Ciudad</option>
          <option class="filterBar__selectOption" v-for="city in cities" :value="city._id" :key="city._id">{{ city.cityName }}</option>
        </select>
        <div
          :class="[`filterBar__item filterBar__item--${item._id}`, feature === item._id && 'selected']"
          v-for="item in features"
          :key="item._id"
          @click="setFeature(item._id)"
        >
          <span v-if="screenWidth > 1497 || (screenWidth < 732 && screenWidth > 590)">{{ item.name }}</span>
          <span v-else>{{ item.name.charAt() }}</span>
        </div>
        <div class="filterBar__deleteFilters" v-if="hasFilters" @click="deleteOtherFilters">
          <iconic class="filterBar__deleteIcon" name="times" />
          <span v-if="screenWidth > 1236">Eliminar filtros</span>
        </div>
      </div>
    </div>
    <div class="filterBar__section" v-if="screenWidth > 1318">
      <i class="fa fa-print filterBar__iconDocument" />
      <iconic class="filterBar__iconDocument" name="excel" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      searchTimeOut: null,
      search: "",
      date: "",
      dateTo: "",
      city: "",
      feature: "",
      resetCalendar: 1,
      keysData: ["search", "date", "dateTo", "city", "feature"],
      features: [
        { _id: "priority", name: "Prioridad" },
        { _id: "satelite", name: "Satélite" },
        { _id: "male", name: "Masculino" },
      ],
    };
  },
  computed: {
    async query() {
      const promises = this.keysData.map(async (key) => {
        if (!this[key]) {
          return "";
        }
        if (key === "search") {
          clearTimeout(this.searchTimeOut);
          return await new Promise((resolveTimeout) => {
            this.searchTimeOut = setTimeout(() => {
              if (!this[key]) {
                resolveTimeout("");
              } else {
                resolveTimeout({ [key]: this[key] });
              }
            }, 400);
          });
        } else {
          return { [key]: this[key] };
        }
      });

      const resolve = await Promise.all(promises);
      return Object.assign({}, ...resolve);
    },
    queryString() {
      const { query, params } = this.$route;

      let queryString = `status=${params?.status || ""}&`;
      Object.entries(query).forEach(([key, val]) => (queryString += `${key}=${val}&`));

      return queryString;
    },
    hasFilters() {
      return this.keysData.some((key) => this[key]);
    },
    datesInFilter() {
      let text = `${this.date.split("-").join("/")}`;

      if (this.dateTo) {
        text += `-${this.dateTo.split("-").join("/")}`;
      }
      if (this.screenWidth < 1112 && text) {
        text = text
          .split("-")
          .map((date) => {
            const [year, month, day] = date.split("/");
            return `${month}/${day}`;
          })
          .join("-");
      }

      return text;
    },
    screenWidth() {
      return this.$screen.width;
    },
    dateInCalendar() {
      return this.date || new Date().toISOString().split("T")[0];
    },
    cities() {
      return this.$store.state.cities;
    },
  },
  watch: {
    async query() {
      await this.executeFilter();
    },
    async $route() {
      this.$store.dispatch("records/searchDocumentsInFilter", this.queryString);
      await this.$store.dispatch("records/executeGetRecords", this.queryString);
    },
  },
  methods: {
    handleChange(values) {
      this.date = values[0]._isValid ? values[0]._d.toISOString().split("T")[0] : "";
      this.dateTo = values[1]._isValid ? values[1]._d.toISOString().split("T")[0] : "";
    },
    setFeature(val) {
      this.feature = this.feature === val ? "" : val;
    },
    async executeFilter() {
      await this.$router.push({ query: await this.query }).catch(() => {});
    },
    deleteOtherFilters() {
      this.keysData.forEach((key) => (this[key] = ""));
    },
  },
  async mounted() {
    const query = this.$route.query;
    this.keysData.forEach((key) => query[key] && (this[key] = query[key]));

    await this.executeFilter();
    await this.$store.dispatch("records/executeGetRecords", this.queryString);
    this.$store.dispatch("records/searchDocumentsInFilter", this.queryString);
  },
};
</script>

<style lang="scss">
.filterBar {
  position: relative;
  @include Flex(row, space-between);
  min-height: 71px;
  width: 100%;
  gap: 10px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px #00000040;
  &__section {
    @include Flex(row, flex-start);
    flex-wrap: wrap;
    gap: 10px;
  }
  &__sectionPart {
    @include Flex(row);
    gap: 10px;
  }
  &__numData {
    @include Flex(row);
    height: 21px;
    padding: 0 8.4px;
    font-size: 13px;
    font-weight: 500;
    color: white;
    background: #5b5957;
    border-radius: 12px;
  }
  &__item {
    position: relative;
    @include Flex(row);
    gap: 5px;
    height: 40px;
    padding: 10px 15px;
    border-radius: 22px;
    border: 1px solid #e5e5e5;
    user-select: none;
    cursor: pointer;
    &--priority {
      color: #2bbb6f;
      border-color: #2bbb6f;
      &.selected {
        background-color: #2bbb6f2b;
      }
    }
    &--satelite {
      color: #ff8c00;
      border-color: #ff8c00;
      &.selected {
        background-color: #ff8c002b;
      }
    }
    &--male {
      color: #4d9bff;
      border-color: #4d9bff;
      &.selected {
        background-color: #4d9bff2b;
      }
    }
  }
  &__search {
    position: relative;
    height: 40px;
    width: 180px;
  }
  &__datesInFilter {
    white-space: nowrap;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__searchInput {
    height: 100%;
    width: 100%;
    padding: 15px 15px 15px 28px;
    font-size: 16px;
    border: 1px solid #e5e5e5;
    border-radius: 22px;
    &:focus {
      outline: none;
    }
  }
  &__iconSearch {
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translate(-50%, -50%);
  }
  &__iconDocument {
    padding: 5px;
    font-size: 22px;
    cursor: pointer;
  }
  &__deleteFilters {
    @include Flex(row);
    gap: 5px;
    padding: 5px;
    cursor: pointer;
    user-select: none;
  }
  &__deleteIcon {
    font-size: 14px;
  }
  .mdrp__activator .activator-wrapper {
    display: none;
  }
  .mdrp-root[data-v-7863e830] {
    position: absolute;
    left: 0;
    bottom: 17px;
    width: 5px;
  }
  @media (max-width: 732px) {
    &__item {
      &--select {
        width: 100%;
      }
    }
    &__sectionPart {
      width: 100%;
    }
    &__search {
      width: 100%;
    }
  }
}
</style>
