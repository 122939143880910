<template>
  <section class="news">
    <h1 class="news__title">Registros Nuevos</h1>
    <div class="news__contentMenus">
      <router-link class="news__menu" v-for="(menu, idx) in menus" :to="menu.to" :key="idx">
        <span>{{ menu.name }}</span>
        <span :class="`news__numData news__numData--${$route.params.status === menu.to.params.status ? 'active  ' : ''}`">{{ menu.numRecords }}</span>
      </router-link>
      <span class="news__line"></span>
    </div>
    <div class="news__container">
      <FilterBar class="news__filterBar" />
      <router-view />
    </div>
  </section>
</template>

<script>
import FilterBar from "../components/mainRecords/FilterBar.vue";
export default {
  components: { FilterBar },
  data() {
    return {
      news: 0,
      disapproved: 0,
    };
  },
  computed: {
    menus() {
      return [
        {
          name: "Nuevos",
          numRecords: this.news,
          to: { name: "News", params: { status: "new" }, query: this.$route.query },
        },
        {
          name: "No aprobados",
          numRecords: this.disapproved,
          to: { name: "Disapproved", params: { status: "disapproved" }, query: this.$route.query },
        },
      ];
    },
  },
  async beforeMount() {
    [this.news, this.disapproved] = await Promise.all([
      await this.$store.dispatch("records/getDocumentsInFilter", `status=new`),
      await this.$store.dispatch("records/getDocumentsInFilter", `status=disapproved`),
    ]);
  },
};
</script>

<style lang="scss">
.news {
  @include Flex(column, flex-start, flex-start);
  gap: 15px;
  padding: 20px;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  &__title {
    font-size: 26px;
  }
  &__contentMenus {
    display: grid;
    grid-template-columns: auto auto 1fr;
    width: 100%;
  }
  &__menu {
    @include Flex(row);
    gap: 10px;
    padding: 10px 15px;
    border-bottom: 3px solid $alto;
    font-weight: 600;
    &.router-link-active {
      color: $primary-color;
      border-bottom: 3px solid $primary-color;
    }
  }
  &__numData {
    @include Flex(row);
    height: 21px;
    padding: 0 8.4px;
    font-size: 13px;
    font-weight: 500;
    color: white;
    background: #5b5957;
    border-radius: 12px;
    &--active {
      background: $primary-color;
    }
  }
  &__line {
    border-bottom: 3px solid $alto;
  }
  &__container {
    position: relative;
    @include Flex(column, flex-start, flex-start);
    gap: 15px;
    width: 100%;
  }
  &__filterBar {
    position: sticky;
    top: 15px;
    z-index: 10;
  }
}
</style>
